<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <ValidationObserver ref="observerRef" v-slot="{ handleSubmit }">
          <form class="form" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card card-custom card-sticky">
              <div class="card-header" style="">
                <div class="card-title">
                  <h3 class="card-label">{{ $t("USER.EDIT_USER") }}</h3>
                </div>
                <CardToolbar
                  @submitEvent="onSubmit"
                  back-url="/users/list"
                  buttonText="Edit User"
                ></CardToolbar>
              </div>
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-10">
                    <div class="row justify-content-center">
                      <div class="col-xl-9">
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("GENERAL.NAME") }}:</label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <input
                                id="firstName"
                                class="form-control"
                                :class="{ 'is-invalid': errors[0] }"
                                v-model="user.firstName"
                                name="name"
                                type="text"
                              />
                            </div>
                          </div>
                        </validation-provider>

                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("GENERAL.LAST_NAME") }}:</label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <input
                                id="lastName"
                                class="form-control"
                                :class="{ 'is-invalid': errors[0] }"
                                v-model="user.lastName"
                                name="name"
                                type="text"
                              />
                            </div>
                          </div>
                        </validation-provider>

                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("GENERAL.EMAIL") }}:</label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                    ><i class="la la-at"></i
                                  ></span>
                                </div>
                                <input
                                  id="email"
                                  class="form-control"
                                  :class="{ 'is-invalid': errors[0] }"
                                  v-model="user.email"
                                  name="name"
                                  type="email"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </validation-provider>

                        <ValidationProvider
                          name="Password"
                          vid="password"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("USER.NEW_PASSWORD") }}:</label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <input
                                type="password"
                                class="form-control"
                                :class="{ 'is-invalid': errors[0] }"
                                v-model="user.plainPassword"
                                :placeholder="$t('USER.NEW_PASSWORD')"
                              />
                            </div>
                          </div>
                        </ValidationProvider>

                        <ValidationProvider
                          rules="confirmed:password"
                          name="Password confirmation"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("USER.REPEAT_NEW") }}:</label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <input
                                type="password"
                                class="form-control"
                                :class="{ 'is-invalid': errors[0] }"
                                v-model="user.plainPasswordConfirmation"
                                :placeholder="$t('USER.REPEAT_NEW')"
                              />
                              <div v-if="errors[0]" class="invalid-feedback">
                                {{ $t("USER.NO_MATCH") }}
                              </div>
                            </div>
                          </div>
                        </ValidationProvider>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("USER.ROLE") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <v-select
                              class="form-control form-control--construction-select"
                              :placeholder="$t('USER.ROLE')"
                              :options="roles"
                              :reduce="(role) => role.value"
                              label="name"
                              @input="onRoleSelect"
                              :value="selectedRole"
                            >
                              <template #option="{ name }">
                                <span
                                  v-if="name === 'Super Admin'"
                                  class="label label-lg label-light-danger label-inline"
                                  >Super Admin</span
                                >
                                <span
                                  v-if="name === 'Admin'"
                                  class="label label-lg label-light-success label-inline"
                                  >Admin</span
                                >
                                <span
                                  v-if="name === 'Baby Admin'"
                                  class="label label-lg label-light-primary label-inline"
                                  >Baby Admin</span
                                >
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <div class="separator separator-dashed my-10"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CardToolbar from "@/view/content/components/card-toolbar/CardToolbar.vue";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required, confirmed } from "vee-validate/dist/rules";
import vSelect from "vue-select";
import ApiService from "@/core/services/api.service";

extend("required", {
  ...required,
  message: "",
});
extend("confirmed", {
  ...confirmed,
  message: "",
});

export default {
  name: "EditUser",
  components: { vSelect, CardToolbar, ValidationProvider, ValidationObserver },
  data() {
    return {
      user: {
        firstName: null,
        lastName: null,
        email: null,
        plainPassword: null,
        plainPasswordConfirmation: null,
        roles: [],
      },
      isUserLoaded: false,
      selectedRole: null,
      roles: [
        {
          name: "Super Admin",
          value: "ROLE_SUPER_ADMIN",
        },
        {
          name: "Admin",
          value: "ROLE_ADMIN",
        },
        {
          name: "Baby Admin",
          value: "ROLE_BABY_ADMIN",
        },
      ],
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("USERS.ALL"), route: "/users/list" },
      { title: this.$t("USERS.EDIT_USER") },
    ]);

    ApiService.get("users/", this.$route.params.id).then(({ data }) => {
      this.user.firstName = data.firstName;
      this.user.lastName = data.lastName;
      this.user.email = data.email;
      this.onRoleSelect(data.roles[0]);
      this.isUserLoaded = true;
    });
  },
  methods: {
    onRoleSelect(val) {
      if (val === "ROLE_SUPER_ADMIN") {
        this.user.roles = ["ROLE_SUPER_ADMIN", "ROLE_USER"];
        this.selectedRole = "Super Admin";
      }
      if (val === "ROLE_ADMIN") {
        this.user.roles = ["ROLE_ADMIN", "ROLE_USER"];
        this.selectedRole = "Admin";
      }
      if (val === "ROLE_BABY_ADMIN") {
        this.user.roles = ["ROLE_BABY_ADMIN", "ROLE_USER"];
        this.selectedRole = "Baby Admin";
      }
    },
    onSubmit(type) {
      this.$refs.observerRef.validate().then((isValid) => {
        if (isValid) {
          ApiService.update("users", this.$route.params.id, this.user)
            .then(() => {
              this.$notify({
                group: "notification",
                type: "success",
                title: this.$t("USER.SUCCESS"),
              });
              if (type === "1") {
                setTimeout(() => {
                  this.$router.push({ name: "users-list" });
                }, 1000);
              }
            })
            .catch((error) => {
              this.$notify({
                group: "notification",
                type: "error",
                title: error,
              });
            });
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("USER.MANDATORY"),
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
